<template>
  <div>
    <upload v-show="!value" upStyle="btn" ref="upload" :upTitle="'添加图片'" @up-success="setUrl" />
    <!-- <el-image v-show="value" :src="value" class="image" @click="upload" /> -->
    <ul class="el-upload-list el-upload-list--picture-card" v-if="value">
      <li tabindex="0" class="el-upload-list__item is-success image">
        <img :src="value" alt="" class="el-upload-list__item-thumbnail image" />
        <!---->
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="reset"><i class="el-icon-delete"></i></span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import upload from '@comps/backStageComponent/upLoads.vue'

export default {
  name: 'FileUpload',
  components: { upload },
  props: {
    value: String,
  },

  methods: {
    setUrl(url) {
      this.$emit('input', url)
    },
    reset() {
      this.$emit('input', '')
    },
  },
}
</script>

<style scoped>
.image {
  margin: 0 !important;
  max-height: 80px;
  width: auto;
  border: none !important;
}
</style>
