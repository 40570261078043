<template>
  <div class="question-store container">
    <el-breadcrumb
      separator="/"
      v-show="tableForm.repoIds && tableForm.repoIds.length > 0"
    >
      <el-breadcrumb-item class="gl_back">
        <i @click="back" class="el-icon-back">返回</i>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <YForm v-model="tableForm" inline class="y-form" size="medium">
      <headerBar />
      <YField
        size="medium"
        prefix-icon="el-icon-search"
        name="content"
        placeholder="请输入题目内容"
      ></YField>
      <YField
        name="quType"
        component="el-select"
        placeholder="题目类型"
        :data-source="QUESTION_TYPE"
      ></YField>
      <YField
        name="repoIds"
        filterable
        multiple
        component="el-select"
        placeholder="选择题库"
        :data-source="questionMap"
      ></YField>
      <YButton do="search" class="btn btn_blue"></YButton>
      <YButton class="btn btn_light_green" @click="importQuestion">
        <i class="el-icon-upload el-icon--left" />
        导入
      </YButton>
      <YButton class="btn btn_blue" @click="addQuestion">
        <i class="el-icon-plus el-icon--left" />
        新建试题
      </YButton>

      <div class="exam-operate">
        <el-link type="info" underline @click="deleteQuestion()" class="mr8">
          <i class="el-icon-delete"></i>
          删除试题
        </el-link>
      </div>

      <YQueryTable
        class="table"
        tooltip-effect="dark"
        ref="queryTable"
        :serve="getList"
        @selection-change="handleSelection"
      >
        <template>
          <el-table-column type="selection" />
          <el-table-column label="题目类型" min-width="120px">
            <template slot-scope="scope">
              {{ QUESTION_TYPE.get(scope.row.quType) }}
            </template>
          </el-table-column>

          <el-table-column
            label="题目内容"
            show-overflow-tooltip=""
            prop="content"
            min-width="180px"
          />
          <el-table-column label="归属题库" prop="repoName" min-width="180px" />
          <el-table-column
            label="创建时间"
            prop="createTime"
            min-width="180px"
          />

          <el-table-column label="操作" prop="name" min-width="120">
            <template slot-scope="scope">
              <el-link class="mr8" @click="edit(scope.row)">编辑</el-link>
              <el-link @click="deleteQuestion([scope.row.id])" type="danger"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </template>
      </YQueryTable>
    </YForm>

    <el-dialog
      class="dialog_wrapper"
      :title="isEdit ? '编辑试题' : '新建试题'"
      :visible.sync="dialogShow"
    >
      <YForm
        v-loading="formLoading"
        ref="addForm"
        v-model="formValues"
        @submit="saveForm"
        labelPosition="top"
      >
        <div class="scroll-form">
          <YField
            component="el-select"
            :disabled="isEdit"
            @change="resetAnswer"
            name="quType"
            label="题目类型"
            inline
            :data-source="QUESTION_TYPE"
            :rules="['required']"
          ></YField>
          <br />
          <YField
            name="repoId"
            :disabled="isEdit"
            inline
            filterable
            component="el-select"
            label="归属题库"
            :data-source="questionMap"
            :rules="['required']"
          ></YField>
          <YField
            type="textarea"
            :autosize="{ minRows: 4 }"
            :maxlength="1000"
            show-word-limit
            label="题目内容"
            name="content"
            :rules="['required', 'whiteSpace']"
          ></YField>
          <YField
            label="题目图片"
            name="image"
            :component="FileUpload"
          ></YField>
          <template v-if="[1, 2].includes(formValues.quType)">
            <div class="flex-between">
              <div class="el-form-item is-success yfield">
                <label
                  class="yfield__label size-small"
                  style="inline-block; text-align: right;"
                  >编辑试题</label
                >
              </div>
              <el-link @click="addAnswer">添加选项</el-link>
            </div>

            <div
              class="tr"
              v-for="(item, i) in formValues.answerList"
              :key="item.id"
            >
              <div class="td mr8 select">
                <YField
                  component="el-checkbox"
                  @change="handleIsRightChange(i)"
                  :name="`answerList[${i}].isRight`"
                ></YField>
              </div>
              <div class="td mr8 content">
                <YField
                  :name="`answerList[${i}].content`"
                  type="textarea"
                  placeholder="请输入选项内容"
                  show-word-limit
                  :maxlength="2500"
                ></YField>
              </div>
              <div class="td mr8 upload">
                <YField
                  :name="`answerList[${i}].image`"
                  :component="FileUpload"
                ></YField>
              </div>
              <div class="td del">
                <el-link type="danger" @click="deleteAnswer(i)">删除</el-link>
              </div>
            </div>
          </template>
          <YField
            v-if="formValues.quType === 3"
            label="编辑答案"
            name="isRight"
            component="el-radio-group"
            :rules="['required']"
          >
            <el-radio :label="1" border>正确</el-radio>
            <el-radio :label="2" border>错误</el-radio>
          </YField>

          <YField
            v-if="formValues.quType === 4"
            label="编辑答案"
            name="answer"
            :rules="['required']"
          ></YField>

          <YField
            type="textarea"
            :maxlength="200"
            show-word-limit
            label="题目解析"
            name="analysis"
          ></YField>
          <YField
            label="解析图片"
            name="analysisImage"
            :component="FileUpload"
          ></YField>
        </div>

        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton
            class="btn btn_cancle btn_normal"
            type="default"
            @click="dialogShow = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>

    <el-dialog title="试题导入" :visible.sync="importDialog">
      <YForm v-model="importForm" @submit="saveImport">
        <div class="logo">
          <div class="xls">
            <img :src="xlsSrc" />
            <span>试题模板</span>
          </div>

          <YButton @click="downTemp" class="btn btn_blue">下载模板</YButton>
        </div>
        <div class="tips">支持题型包括单选题、多选题、判断题、填空</div>
        <h3 class="title">导入方法</h3>
        <el-steps :active="3" class="step" align-center>
          <el-step
            description="请先下载对应格式模板"
            icon="el-icon-circle-check"
          ></el-step>
          <el-step
            description="按格式在模板上编辑试题"
            icon="el-icon-circle-check"
          ></el-step>
          <el-step
            description="上传编辑好的模板"
            icon="el-icon-circle-check"
          ></el-step>
        </el-steps>
        <div class="tips">注意：表格表头请勿删除列，没有内容的请置空</div>
        <YField
          name="repoId"
          :disabled="isEdit"
          inline
          filterable
          component="el-select"
          label="归属题库"
          :data-source="questionMap"
          :rules="['required']"
        ></YField>
        <el-upload
          class="upload-demo"
          :on-change="getFileList"
          ref="upload"
          :limit="1"
          :file-list="fileList"
          :auto-upload="false"
        >
          <el-button
            slot="trigger"
            icon="el-icon-upload"
            class="btn"
            type="success"
            >上传模板</el-button
          >
        </el-upload>
        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton
            class="btn btn_cancle btn_normal"
            type="default"
            @click="importDialog = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>
  </div>
</template>

<script>
const SELECT_COOUNT_MIN = 2; // 选项最小数量
const SELECT_COOUNT_MAX = 5; // 选项最大数量

import { QUESTION_TYPE } from "@/utils/enum.js";
import { deleteRequest } from "@/utils/index.js";
import {
  fetchQuestionList,
  fetchAllQuestionStore,
  saveQuestion,
  fetchQuestionDetail,
  getQustionTemp,
  deleteQuestion,
  questionImport,
} from "@/apis/backStageEndAPI/examStageManagement/index.js";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import FileUpload from "./components/FileUpload";
import SetQuestion from "./components/setQuestion.vue";
import cloneDeep from "lodash/cloneDeep";
const xlsSrc = require("@/assets/img/backStageImg/xls.svg");

export default {
  data() {
    return {
      fileList: [],
      xlsSrc,
      SetQuestion,
      FileUpload,
      formLoading: true,
      importDialog: false,
      tableForm: {},
      formValues: {},
      questionMap: null,
      importForm: {},
      QUESTION_TYPE,
      selectedRows: [],
      isEdit: false,
      selectedQuestionStore: [],
      dialogShow: false,
      currentIndex: 0,
      tabList: ["试卷设置", "题型设置", "考试设置"],
      columns: [
        { label: "题库名称", prop: "title" },
        { label: "题库类别", prop: "type" },
        { label: "题目数量", prop: "totalCout" },
      ],
      params: {
        from: null,
        path: "",
        token: "",
      },
    };
  },
  created() {
    this.getAllQuestion();
    this.init();
  },
  components: { headerBar },
  mounted() {
    this.params = this.$store.state.fusion;
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    handleIsRightChange(i) {
      const flag = this.formValues.answerList[i].isRight;
      if (!flag) return;
      if (this.formValues.quType === 1) {
        this.formValues.answerList.forEach((e) => {
          e.isRight = false;
        });
        this.formValues.answerList[i].isRight = true;
      }
    },
    clearValidate(dom) {
      dom = dom || this.$refs.addForm;
      dom && this.$nextTick(dom.clearValidate);
    },
    resetAnswer(val) {
      if ([3, 4].includes(val)) {
        this.formValues.answerList = [];
      } else {
        this.formValues.answerList = [
          { isRight: false },
          { isRight: false },
          { isRight: false },
          { isRight: false },
        ];
      }
    },

    init() {
      this.tableForm.repoIds = this.$route.query.id && [this.$route.query.id];
      console.log(this.tableForm.repoIds);
    },

    getFileList(file, fileList) {
      this.fileList = fileList;
    },

    async saveImport() {
      await questionImport(this.fileList[0].raw, this.importForm);
      this.$refs.queryTable.runServe({ currentPage: 1 });
    },

    importQuestion() {
      this.importForm = {
        repoId: this.$route.query.id,
      };
      this.importDialog = true;
    },

    addAnswer() {
      if (this.formValues.answerList.length >= 5) {
        return this.$message.warning(`选项不能多于${SELECT_COOUNT_MAX}个`);
      }
      this.formValues.answerList.push({ isRight: false });
    },

    deleteAnswer(index) {
      const len = this.formValues.answerList.length;
      if (len <= SELECT_COOUNT_MIN) {
        return this.$message.warning(`选项不能少于${SELECT_COOUNT_MIN}个`);
      }
      this.formValues.answerList.splice(index, 1);
    },

    async getAllQuestion() {
      const { data = [] } = await fetchAllQuestionStore();
      const map = new Map();
      data.forEach((e) => {
        map.set(e.id, e.title);
      });
      this.questionMap = map;
    },

    async deleteQuestion(ids) {
      ids = ids || this.selectedRows.map((e) => e.id);
      await deleteRequest(deleteQuestion, ids);
      this.$refs.queryTable.runServe();
    },

    addQuestion() {
      this.formValues = {
        answerList: [
          { isRight: false },
          { isRight: false },
          { isRight: false },
          { isRight: false },
        ],
        quType: 1,
        repoId: this.$route.query && this.$route.query.id,
      };
      this.isEdit = false;
      this.currentIndex = 0;
      this.dialogShow = true;
      this.formLoading = false;
      this.clearValidate();
    },

    async edit(row) {
      try {
        this.formLoading = true;
        this.isEdit = true;
        const { data = {} } = await fetchQuestionDetail({
          id: row.id,
        });
        this.formLoading = false;
        const { repoIds, quType, answerList = [] } = data;

        this.formValues = {
          ...data,
          repoId: repoIds[0],
        };
        if (quType === 3) {
          this.formValues.isRight = answerList[0].isRight ? 1 : 2;
        }
        delete this.formValues.createTime;
        delete this.formValues.updateTime;

        const { repoList = [] } = this.formValues;
        this.selectedQuestionStore = cloneDeep(repoList);
        this.dialogShow = true;
        this.clearValidate();
      } catch (e) {
        this.$message.error(e?.message);
        this.dialogShow = false;
      }
    },

    async downTemp() {
      const loading = this.$loading({
        lock: true,
        text: "正在下载",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const res = await getQustionTemp();
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        // 获得文件名称
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "题目模板.xlsx");
        link.click();
        link = null;
      } finally {
        loading.close();
      }
    },

    async saveForm() {
      try {
        const { quType } = this.formValues;
        switch (quType) {
          case 3:
            if (!this.formValues.answerList.length) {
              this.formValues.answerList = [
                {
                  content: "正确",
                  isRight: this.formValues.isRight === 1,
                },
                {
                  content: "错误",
                  isRight: this.formValues.isRight === 2,
                },
              ];
              // 编辑状态
            } else {
              this.formValues.answerList[0].isRight =
                this.formValues.isRight === 1;
              this.formValues.answerList[1].isRight =
                this.formValues.isRight === 2;
            }
            break;

          case 4:
            this.formValues.answerList = [
              {
                content: this.formValues.answer.trim(),
                isRight: true,
              },
            ];

          default:
            break;
        }
        const length = this.formValues.answerList.filter(
          (e) => e.isRight === true
        ).length;
        const contentLenght = this.formValues.answerList.filter(
          (e) => e.content
        ).length;
        if (contentLenght !== this.formValues.answerList.length) {
          return this.$message.warning("请填写所有选项内容");
        }
        if (length < 1) {
          return this.$message.warning("至少包含1个正确答案");
        }
        if ((quType === 2) & (length <= 1)) {
          return this.$message.warning("多选题至少2个正确答案");
        }
        if ((quType === 1) & (length > 1)) {
          return this.$message.warning("单选题只能选择1个正确答案");
        }
        await saveQuestion({
          ...this.formValues,
          repoIds: [this.formValues.repoId],
        });
        this.dialogShow = false;
        this.$refs.queryTable.runServe({ currentPage: 1 });
      } catch (e) {
        this.dialogShow = false;
      }
    },

    handleSelection(rows) {
      this.selectedRows = rows;
    },

    async getList({ formValues, params }) {
      const { data } = await fetchQuestionList({
        current: params.currentPage,
        size: params.pageSize,
        params: formValues,
      });
      return {
        data: data?.records,
        total: data?.total,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .gl_back.el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: var(--colorBlue) !important;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .el-breadcrumb {
    margin-bottom: 20px;
  }
}
.exam- {
  &operate {
    margin-bottom: 12px;
    text-align: right;
    .el-icon-delete {
      color: #f65160;
    }
  }
}
.title {
  margin-bottom: 12px;
  height: 25px;
  color: #0a1629;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  line-height: 25px;
}

.scroll-form {
  overflow-y: scroll;
  margin: 0 -20px 20px;
  padding: 0 20px;
  height: 450px;
}

.xls {
  display: flex;
  align-items: center;

  img {
    margin-right: 30px;
  }
  span {
    width: 100px;
  }
}
.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}
.tips {
  margin-bottom: 20px;
  height: 17px;
  color: #ced5e0;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 17px;
}
.tr {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 12px;
  .td {
    &.del {
      flex: 0 0 40px;
      margin-bottom: 20px;
    }
    &.select {
      flex: 0 0 20px;
      text-align: center;
    }
    &.upload {
      overflow: hidden;
      flex: 0 0 120px;
      text-align: center;
    }
    &.content {
      flex: 1;
    }
  }
}
::v-deep {
  .el-radio--small.is-bordered {
    height: 36px;
  }
  .el-step.is-center .el-step__description {
    padding: 12px 0;
  }
}
</style>
